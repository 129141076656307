<template>
    <v-app id="login" :style="backgroundStyle">
        <!--
            <div class="my-2 mr-5" style="width:100%;">
                
                <v-btn dark text @click="register" style='float:right;'>Register</v-btn>
                <v-btn dark text @click="search" style='float:right;'>Search</v-btn>
            </div>
        -->
            <form>
                <v-card elevation="24" max-width="444" class="ml-auto login " rounded="lg" color="black">
                    <div>
                        <br/>
                        <v-img max-width="350px" class="mx-auto" src="@/assets/logo_ratex2.png"></v-img>
                        <br/>
                        
                        <v-text-field  background-color="white" prepend-inner-icon="mdi-account" label="User's ID" :rules="rules" required v-model="userid" outlined></v-text-field>
                        <br/>
                        <v-text-field background-color="white" prepend-inner-icon="mdi-lock" label="Password" type="password" :rules="rules" required v-model="password" outlined ></v-text-field>
                        
                        <div class='smaller' style='float:left;color:white;'><input type="checkbox" name="remember"  v-model="remember" /> Remember Me</div>
                        <!--<a class='smaller' href='#' style='float:right'> Forgot Password</a>-->
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <v-btn color="#92D050"  class="signin " large tile @click="login"><span style="font-size:x-large;text-transform:none;">Sign In</span></v-btn>
                        <br/>
                        <br/>
                        <small>
                            <center>
                            
                            <br/>
                            
                            <br/>
                            
                            </center>
                        </small>
                    </div>
                </v-card>
            </form>
            <v-footer absolute color="white"><v-row class='float-right'><v-col><small><center><a href="https://beian.miit.gov.cn">工業和信息化部備案管理系統網站 粵ICP備12090450號</a> 主辦單位: 深圳市鴻安貨運代運有限公司 Operated by Honour Lane Shipping Ltd @2020</center></small></v-col></v-row></v-footer>
    </v-app>
</template>

<script>

    import backgroundUrl from "@/assets/ratex_bg.png";
    import { SystemLogin } from "@/api/publicServices";
    export default {
        name: 'Login',
        mounted () {
            let site_userid = localStorage.getItem("site_userid");
            if (site_userid != null) {
                this.userid = site_userid;
            }
        },
        data: () => ({
            backgroundStyle: {
            "background-image": `url(${backgroundUrl})`,
            "background-size": "100%"
            },
            rules: [
            value => !!value || 'Required.',
            value => (value && value.length >= 3) || 'Min 3 characters',
            ],
            userid :"",
            password: "",
            pol: {},
            remember: false
        }),
        methods: {
            async login(e) {
                e.preventDefault();
                if (this.password.length > 0) {
                    
                    try {
                        const response = await SystemLogin(this.userid, this.password);

                        if (response.data.UserID != "" && response.data.UserID != undefined ) {
                            localStorage.setItem('userid', response.data.UserID);
                            localStorage.setItem('password',this.password);
                            localStorage.setItem('jwt',response.data.UserID);
                            localStorage.setItem('user_type', (response.data.IsAgent)?"AGENT":"USER");
                            
                            if (this.remember) {
                                localStorage.setItem('site_userid', response.data.UserID);
                            }
                            if (response.data.IsAgent) {
                                this.$router.push('agent');
                            } else {
                                this.$router.push('home');
                            }
                        } else {
                            alert("User Name or Password Error")
                        }
                    } catch (error) {
                        console.log(error);
                    } 
                }
            },
            register() {
                this.$router.push('register');
            },
            search() {
                this.$router.push('search_quotes');
            }
        
        }
    }
</script>

<style scoped>
.login {
        width: 400px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        padding: 20px;
        margin-top: 150px;
        margin-right: 70px;
    }
.textbox {

        width: 100%;
        border: 1px solid #CCCCCC;
}
.smaller {
    font-size: smaller
}
.notice {
    font-family: 'Century Gothic';
}

.signin {
    font-family: 'Century Gothic';
    width: 100%;
}
</style>