<template>
    <div id="main-menu" >
        <v-card color="black" flat>
            <v-toolbar dark  color="black" class="menu" style="background-color: black;">
                <v-toolbar-title><v-img src="@/assets/logo_ratex_menu.png"></v-img></v-toolbar-title>
                
                <v-spacer v-show="userType=='AGENT'"></v-spacer>
                <v-btn v-show="userType=='AGENT'" icon to="/agent">
                  <v-icon>mdi-currency-usd</v-icon>Qutoes
                </v-btn>

                <v-spacer v-show="userType=='USER'"></v-spacer>
                <v-btn v-show="userType=='USER'" icon to="/quotes">
                  <v-icon>mdi-currency-usd</v-icon>Qutoes
                </v-btn>
                <v-spacer v-show="userType=='USER'"></v-spacer>
                <v-btn v-show="userType=='USER'" icon to="/booking">
                  <v-icon>mdi-calendar</v-icon>Booking
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon to="/ratesheet">
                <v-icon>mdi-clipboard-text-multiple-outline</v-icon>Rate Sheet
                </v-btn>
                <!--
                <v-spacer></v-spacer>
                <v-btn icon href="/tracking">
                <v-icon>mdi-magnify</v-icon>Tracking
                </v-btn>
                -->
                <v-spacer v-show="userType=='USER'"></v-spacer>
                <v-btn v-show="userType=='USER'" icon to="/notification">
                  <v-icon>mdi-bell</v-icon>Notification
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon to="/personal">
                  <v-icon>mdi-cog</v-icon>Setting
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn icon @click="logout">
                  <v-icon>mdi-logout</v-icon>Logout
                </v-btn>
                <v-spacer></v-spacer>
            </v-toolbar>
            
            <v-spacer background-color="lightblue"></v-spacer>
        </v-card>
        
    </div>
</template>

<script>

  export default {
    name: 'MainMenu',
    mounted () {
        this.userType = localStorage.getItem("user_type");
        console.log(this.userType)
    },
    data: () => ({
      userType:""
    }),
    methods: {
        logout() {
            localStorage.removeItem('user');
            localStorage.removeItem('jwt');
            localStorage.removeItem('password');
            localStorage.removeItem('user');
            localStorage.removeItem('user_type');

            this.$router.replace({ name: "login" });
        
        }
    }
  }
  </script>


<style scoped>

.v-card>:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.v-card>.v-card__progress+:not(.v-btn):not(.v-chip), .v-card>:first-child:not(.v-btn):not(.v-chip){
    border-top-right-radius: unset;
  border-top-left-radius: unset;
}
</style>